<template>
    <ValidationObserver ref="validator" slim>
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >
            <v-toolbar flat>
                <v-toolbar-title>{{ $t('src\\views\\dictionaries\\edit.6070') }}</v-toolbar-title>
            </v-toolbar>
            <ValidationProvider v-slot="{ errors }" rules="required">
                <v-text-field
                    v-model="entity.code"
                    :label="$t('src\\views\\dictionaries\\edit.4472')"
                    outlined
                    :error-messages="errors[0]"
                />
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors }" rules="required">
                <v-text-field
                    v-model="entity.name"
                    :label="$t('src\\views\\dictionaries\\edit.848')"
                    outlined
                    :error-messages="errors[0]"
                />
            </ValidationProvider>
            <v-row class="mx-0">
                <v-toolbar-title>{{ $t('src\\views\\dictionaries\\edit.4478') }}</v-toolbar-title>
            </v-row>
            <v-row class="mx-0">
                <template v-for="(value, index) in entity.values">
                    <v-col
                        :key="index"
                        cols="6">
                        <div
                            class="pa-6 mx-0 my-2"
                            style="border: 1px solid #00000061; border-radius: 4px;">
                            <v-text-field
                                v-model="value.code"
                                :label="$t('src\\views\\dictionaries\\edit.4981')"
                                outlined
                            />
                            <v-text-field
                                v-model="value.title"
                                :label="$t('src\\views\\dictionaries\\edit.2503')"
                                outlined
                            />
                            <v-img :src="imageSrc(value.picture)" />
                            <v-file-input
                                :label="$t('src\\views\\dictionaries\\edit.2599')"
                                outlined
                                prepend-icon="mdi-image"
                                @change="setPicture(index, $event)"
                            />
                            <v-textarea
                                v-model="value.text"
                                :label="$t('src\\views\\dictionaries\\edit.8816')"
                                auto-grow
                                outlined
                            />
                            <v-btn
                                color="error"
                                @click="removeValue(index)">{{ $t('src\\views\\dictionaries\\edit.532') }}</v-btn>
                        </div>
                    </v-col>
                </template>
            </v-row>
            <v-row
                align="center"
                justify="space-between"
                class="mx-0 my-2">
                <v-btn
                    :disabled="!valid"
                    color="primary"
                    @click="addValue">{{ $t('src\\views\\dictionaries\\edit.6363') }}</v-btn>
            </v-row>
            <v-row
                align="center"
                justify="space-between"
                class="mx-0">
                <v-btn
                    :disabled="!valid"
                    color="primary"
                    @click="save">{{ $t('src\\views\\dictionaries\\edit.4771') }}</v-btn>
                <v-btn
                    v-if="entity._id"
                    color="error"
                    @click="remove">{{ $t('src\\views\\dictionaries\\edit.273') }}</v-btn>
            </v-row>
        </v-form>
    </ValidationObserver>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { imageSrc } from '../../helpers';

    export default {
        name: 'DictionariesEdit',
        components: {
            ValidationProvider,
            ValidationObserver,
        },
        data: () => ({
            imageSrc,
            valid: true
        }),
        computed: {
            ...mapState('dictionaries', ['entity']),
        },
        async mounted() {
            if(this.$route.params.id) {
                await store.dispatch('dictionaries/get', { id: this.$route.params.id });
            } else {
                await store.commit('dictionaries/CLEAR_ENTITY');
            }
        },
        methods: {
            async setPicture(index, file) {
                this.entity.values[index].picture = await store.dispatch('files/upload', { file });
            },
            addValue() {
                this.entity.values.push({
                    code: '',
                    title: '',
                    picture: '',
                    text: ''
                });
            },
            removeValue(index) {
                this.entity.values.splice(index, 1);
            },
            async save() {
                this.valid = await this.$refs.validator.validate();
                if(this.valid){
                    await store.commit('dictionaries/SET_ENTITY', this.entity);
                    await store.dispatch('dictionaries/save');
                    await this.$router.push({ name: 'dictionaries-list' });
                }
            },
            async remove() {
                await store.dispatch('dictionaries/delete', { id: this.entity._id });
                await this.$router.push({ name: 'dictionaries-list' });
            }
        }
    }
</script>

<style lang="scss">
    .v-image {
        width: 300px;
        margin: 0 auto 20px;
        border-radius: 6px;
    }
</style>
